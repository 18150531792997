import React, { Component, Suspense } from "react";
import { HashRouter, Route, Routes, BrowserRouter } from "react-router-dom";
import "./scss/style.scss";
import "../src/App.css";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import ProtectRoute from "./ProtectRoute";
import { CPlaceholder } from "@coreui/react";
import AuthGuard from './client/components/common/AuthGuard'
import BookPetTraining from "./client/pages/BookPetTraining";
import Training from "./client/pages/Training";
import TestsDemos from "./client/pages/TestsDemos";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./admin/layout/DefaultLayout"));
const ProtectedInit = React.lazy(() => import('./protectedInit'));


// Pages
const Login = React.lazy(() => import("./admin/views/pages/login/Login"));
const Register = React.lazy(() => import("./admin/views/pages/register/Register"));
const Page404 = React.lazy(() => import("./admin/views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./admin/views/pages/page500/Page500"));

const Home = React.lazy(() => import("./client/pages/Home"));
const NewHomePage = React.lazy(() => import("./client/pages/NewHome"))
const Thankyou = React.lazy(() => import("./client/pages/Tnakyou"));
const PaymentPage = React.lazy(() => import("./client/pages/paymentPage"));
const OrderPage = React.lazy(() => import("./client/pages/order"));
const Profile = React.lazy(() => import("./client/pages/profile"));
const SingUp = React.lazy(() => import("./client/pages/singup"));
const ProfileDetails = React.lazy(() => import("./client/pages/profileDetails"));
const PetDetails = React.lazy(() => import("./client/pages/petDetails"));
const MyAddress = React.lazy(() => import("./client/pages/MyAddress"));
const StepPage = React.lazy(() => import("./client/pages/stepper"));
const MyBookingNav = React.lazy(() => import("./client/pages/MyBookingNav"));
const MyServices = React.lazy(() => import("./client/pages/MyServices"));
const BecomePartner = React.lazy(() => import("./client/pages/BecomePartner"))
const MenueDragDrop = React.lazy(() => import("../src/admin/components/sidebarMenueDragDrop"))
const BookPetWalking = React.lazy(() => import("../src/client/pages/BookPetWalking"))
const RehomePet = React.lazy(() => import("../src/client/pages/RehomePet"))
class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>

            {/* Client side route start */}
            <Route exact path="/daf" name="Home Page" element={<Home />} />
            <Route exact path="/" name="Home Page" element={<NewHomePage />} />
            {/* <Route
              exact
              path="/thankyou"
              name="Thankyou Page"
              element={<Thankyou />}
            />~ */}
            <Route
              exact
              path="/payment"
              name="Payment Page"
              element={<PaymentPage />}
            />
            <Route
              exact
              path="/orders"
              name="Orders Page"
              element={<AuthGuard ><OrderPage /></AuthGuard>}
            />
            <Route
              exact
              path="/profile"
              name="Orders Page"
              element={<Profile />}
            />
            <Route
              exact
              path="/singup"
              name="Singup pages"
              element={<SingUp redirect={true} />}
            />
            <Route
              exact
              path="/pet-details"
              name="Pet Details"
              element={<AuthGuard><PetDetails /></AuthGuard>}
            />

            <Route
              exact
              path="/my-address"
              name="my address"
              element={<AuthGuard><MyAddress /></AuthGuard>}
            />

            <Route
              exact
              path="/step-page"
              name="step-page"
              element={<StepPage />}
            />
            <Route
              exact
              path="/profile-page"
              name="profile pages"
              element={<ProfileDetails />}
            />
            <Route
              exact
              path="/book-pet-training"
              name="book pet training"
              element={<AuthGuard><BookPetTraining /></AuthGuard>}
            />
            <Route
              exact
              path="/trainings"
              name="trainings"
              element={<AuthGuard><Training /></AuthGuard>}
            />
            <Route
              exact
              path="/mybookings"
              name="mybookings"
              element={<AuthGuard><MyBookingNav /></AuthGuard>}
            />
            <Route
              exact
              path="/myservices"
              name="myservices"
              element={<MyServices />}
            />
            <Route
              exact
              path="/become-a-partner"
              name="become a partner"
              element={<BecomePartner />}
            />
            <Route
              exact
              path="/book-pet-walking"
              name="book pet walking"
              element={<BookPetWalking />}
            />
            <Route
              exact
              path="/rehome-a-pet"
              name="rehome a pet"
              element={<RehomePet />}
            />
            <Route
              exact
              path="/tests-demos"
              name="tests demos"
              element={<TestsDemos />}
            />


            {/* Client side route end */}

            {/* Admin side route start */}
            <Route exact path="/admin/login" name="Login Page" element={<Login />} />

            <Route
              exact
              path="/admin/register"
              name="Register Page"
              element={<Register />}
            />

            <Route path="/admin" element={<ProtectRoute />}>
              <Route index name="Home" element={<ProtectedInit />} />
              <Route path="*" name="Home" element={<DefaultLayout />} />
            </Route>

            <Route exact path="/testmenue" name="testmenue" element={<MenueDragDrop />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            {/* Admin side route start */}
          </Routes>
          <ToastContainer />
        </Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
