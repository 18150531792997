import React from 'react'
import { useSelector } from 'react-redux'
import Cookies from 'js-cookie'
import { Navigate, Outlet, useNavigate } from 'react-router-dom'

const ProtectRoute = ({ children }) => {
  console.log("protected entry", children)
  let isAuthenticated = Cookies.get('auth') ? Cookies.get('auth') : localStorage.getItem("_auth");

  if (!isAuthenticated) {
    return <Navigate to="/admin/login" />
  } else {
    <Outlet />
    // return <Navigate to="/admin/dashboard" />
  }

  return children ? children : <Outlet />
}

export default ProtectRoute
