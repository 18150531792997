import React from 'react'
import { Helmet } from 'react-helmet'

const MetaHead = (pageData) => {
    return (
        <>
            <Helmet>
                <title>{pageData.title || "PinkPaws"}</title>
                <meta name="description" content={pageData.description || "PinkPaws"} />
                <meta property="og:title" content={pageData.title || "PinkPaws"} />
                <meta property="og:description" content={pageData.description || "PinkPaws"} />
                <meta property="og:url" content={pageData.url || "https://pgr.altisinfonet.in/"} />
                <meta property="og:image" content={pageData.image || "PinkPaws"} />
            </Helmet>
        </>
    )
}

export default MetaHead