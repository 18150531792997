import React, { useEffect } from "react";
import { Navigate, Route } from "react-router-dom";
import { checkAuth, tostaHit } from "src/client/utils/helpers";

const AuthGuard = ({ children }) => {
  const isAuthenticated = checkAuth();

  useEffect(() => {
    if (!isAuthenticated) {
      tostaHit("Please login at first");
    } else {
      null;
    }
  });

  return <>{isAuthenticated ? children : <Navigate to="/" />}</>;
};

export default AuthGuard;
